import React from "react";
import {Route, Redirect} from "react-router-dom";
import Cookies from 'universal-cookie';

const  cookies = new Cookies();

export const ProtectedRoute = ({component: Component, ...rest}) => {
    return (
        <Route
            {...rest}
            render={props => {
                if (cookies.get('isLoggedIn') === "true") {
                    return <Component {...props} />;
                } else {
                    return (
                        <Redirect to={{
                            pathname: "/login",
                            state: {
                                from: props.location
                            }
                        }}/>
                    );
                }
            }}
        />
    );
};
