import api from "../Api/api";

class Auth {

   //Authenticate for login
   static login (email,password,callback){
       let credentials  = {
           email:email,
           password:password
       };
       api.post('auth/login',credentials).then(callback);
   }
}
export default Auth