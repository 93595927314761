import React, {Fragment} from 'react';
import {
    Button,
    Col,
    Drawer,
    Form,
    Input,
    PageHeader,
    DatePicker,
    message,
    Row,
    Select,
    Table,
    Tooltip,
    Icon,
    Divider, Descriptions, Popconfirm
} from "antd";
import api from "../Api/api";

const {Option} = Select;


const view = <span>view</span>;
const edit = <span>edit</span>;
const remove = <span>delete</span>;
const change_password = <span>change_password</span>

//Delete method popfeilled
function confirm(e) {
    console.log(e);
    message.success('Click on Yes');
};

function cancel(e) {
    console.log(e);
    message.error('Click on No');
};

class newForm extends React.Component {


    constructor() {
        super();
        this.state = {
            data: [],
            getUserData: {},
            visible: false,
            viewDrawer: false,
            viewUserDrawer: false,
            name: '',
            email: '',
            mobile: '',
            role: '',
            old_password: '',
            new_password: '',
            cconfirm_password: '',
            rowKey: null,
            loading: true,
            drawerType: null,
            paginateTotal: 1
        };
        this.addUser = this.addUser.bind(this);
        this.deleteUser = this.deleteUser.bind(this);
        this.changePassword = this.changePassword.bind(this);
        this.editUserData = this.editUserData.bind(this);
        this.getUser = this.getUser.bind(this);
        this.onPaginate = this.onPaginate.bind(this)
    }

    //Drawer open method for User Password
    showDrawer = () => {
        this.setState({
            visible: true,
        });

    };


    onClose = () => {
        this.setState({
            visible: false,
        });

    };


    //Drawer open for Add User
    openDrawer = () => {
        this.setState({
            viewDrawer: true,
            drawerType: "post"
        })

    };

    onclose = () => {
        this.setState({
            viewDrawer: false
        })
        this.props.form.resetFields();
    };


    //data get database to user's table
    componentDidMount() {
        api.get('auth/users')
            .then((response) => {
                this.setState({
                    paginateTotal: response.data.total
                })
                var userarr = response.data.data.map((data) => {
                    return {
                        id: data.id,
                        key: data.id,
                        name: data.name,
                        email: data.email,
                        mobile: data.mobile,
                        role: data.role,
                        action: ['view', 'edit', 'delete', 'change_password']
                    };
                });
                this.setState({
                    data: userarr,
                    loading: false
                })
            })
    }

    //data post in submit click database and table
    addUser(e) {

        e.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({disabled: true});

        this.props.form.validateFields(['name', 'email', 'mobile', 'role', 'password', 'confirm_password'], (err, values) => {
            console.log('Received values of form: ', values);
            if (!err && values.confirm_password === values.password) {
                message.loading("please wait...").then(() => {
                    api.post('auth/users', values)
                        .then(response => {
                            console.log(response);
                            if (response.data.success) {
                                this.setState({
                                    viewDrawer: false,
                                    disabled: false,
                                });
                                const {data} = this.state;
                                var newData = data.map(item => ({...item}));
                                newData.unshift({
                                    id: response.data.user.id,
                                    key: response.data.user.id,
                                    name: response.data.user.name,
                                    email: response.data.user.email,
                                    mobile: response.data.user.mobile,
                                    role: response.data.user.role,
                                    action: ['view', 'edit', 'delete', 'change_password']
                                });
                                console.log(newData);
                                this.setState({
                                    data: newData,
                                    disabled: false
                                })
                            }
                            this.props.form.resetFields();
                        })
                        .catch((err) => {
                            console.log(err.message)
                        })
                })
            } else {
                message.error("Passwords does not match!");
                this.props.form.resetFields();
                this.setState({
                    disabled: false
                })
            }
        })
    };

    //View Users data get api
    getUser(id, type) {
        console.log(id);
        api.get(`auth/users/${id}`)
            .then((response) => {
                this.setState({
                    getUserData: response.data.user,
                })

                console.log(type)
                if (type === "view") {
                    this.setState({
                        viewUserDrawer: true
                    })
                } else if (type === "edit") {
                    this.setState({
                        viewDrawer: true,
                        drawerType: "edit"
                    })
                    this.props.form.setFieldsValue({
                        name: response.data.user.name,
                        email: response.data.user.email,
                        mobile: response.data.user.mobile,
                        role: response.data.user.role
                    })
                }
            })
    }

    closeDrawer = () => {
        this.setState({
            viewUserDrawer: false
        })
    };

    //Change Password put method
    changePassword = (e) => {
        e.preventDefault();
        console.log(this.state.rowKey);
        message.loading("please wait...").then(() => {
            this.setState({visible: true});
            this.props.form.validateFields(['old_password', 'new_password', 'cconfirm_password'], (err, values) => {
                if (!err) {
                    console.log('Received values of form: ', values);
                    api.put('auth/users/changePassword/' + this.state.rowKey, values)
                        .then(response => {
                            console.log(response);
                            if (response.data.success) {
                                this.setState({
                                    visible: false,
                                    disabled: false,
                                })
                            }
                            this.props.form.resetFields();
                        }).catch(function (err) {
                        console.log(err)
                    })
                }
            })

        })
    };

    getRowByKey(key, newData) {
        const {data} = this.state;
        return (newData || data).filter(item => item.id == key)[0]
    }

    //Edite User's detials
    editUserData(e) {
        e.preventDefault();
        if (this.state.disabled) {
            return;
        }
        this.setState({disabled: true});

        message.loading("please wait...").then(() => {
            this.props.form.validateFields(['name', 'email', 'mobile', 'role'], (err, values) => {
                console.log('Received values of form: ', values);
                console.log(this.state.getUserData);
                if (!err) {
                    api.put(`auth/users/${this.state.getUserData.id}`, values)
                        .then(response => {
                            console.log(response);
                            if (response.data.success) {
                                this.setState({
                                    viewDrawer: false,
                                    disabled: false,
                                });
                                const {data} = this.state;
                                var newData = data.map(item => ({...item}));
                                console.log(newData);
                                const target = this.getRowByKey(response.data.user.id, newData);
                                console.log(target);
                                if (target) {
                                    target['name'] = response.data.user.name;
                                    target['email'] = response.data.user.email;
                                    target['mobile'] = response.data.user.mobile;
                                    target['role'] = response.data.user.role;
                                }
                                this.setState({
                                    data: newData,
                                    disabled: false
                                })
                            }
                            this.props.form.resetFields();
                        })
                        .catch((err) => {
                            console.log(err.message)
                        })
                }
            })
        });
    };

    onPaginate(page) {
        console.log(page)
        this.setState({
            loading: true,
        });
        api.get('auth/users?page=' + page).then((response) => {
            console.log(response)
            this.setState({
                paginateTotal: response.data.total,
            });
            var dataArr = []
            response.data.data.map((data) => {
                var type = {
                    id: data.id,
                    key: data.id,
                    name: data.name,
                    email: data.email,
                    mobile: data.mobile,
                    role: data.role,
                    action: ['view', 'edit', 'delete', 'change_password']
                };
                dataArr.push(type)
            });
            this.setState({
                data: dataArr,
                loading: false
            });

        }).then(() => {
            this.setState({
                loading: false
            })
        })
    }

    //delete customer
    deleteUser = (id) => {
        console.log(id);
        message.loading("Please wait")
            .then(() => {
                api.delete(`auth/users/${id}`)
                    .then((response) => {
                        console.log(response)
                        const {data} = this.state;
                        const newData = data.filter(item => item.id != response.data.user.id);
                        console.log(newData)
                        this.setState({
                            data: newData
                        });
                        message.success("SuccessFully Delete Row");
                    })
            })
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        //Table columns
        const columns = [
            {title: 'User ID', dataIndex: 'id', key: 'id'},
            {title: 'Name', dataIndex: 'name', key: 'name'},
            {title: 'email', dataIndex: 'email', key: 'email'},
            {title: 'Mobile No', dataIndex: 'mobile', key: 'mobile'},
            {title: 'Role', dataIndex: 'role', key: 'role'},
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'x',
                render: (text, record) => (
                    <span>
                          <Tooltip title="View">
                               <Button type={text[1]} size="25px" onClick={() => {
                                   this.getUser(record.id, "view")
                               }} icon="eye" shape="circle" style={{marginLeft: 7}}/>
                          </Tooltip>
                         <Tooltip title="Edit">
                               <Button type={text[2]} name="btn" size="25px" onClick={() => {
                                   this.getUser(record.id, "edit")
                               }} icon="edit" shape="circle"
                                       style={{marginLeft: 7}}/>
                          </Tooltip>
                       <Popconfirm
                           title="Are you sure delete this task?"
                           onConfirm={() => {
                               this.deleteUser(record.id, "delete")
                           }}
                           onCancel={cancel}
                           okText="Yes"
                           cancelText="No"

                       >
                        <Tooltip title="remove">
                               <Button type={text[3]} name="btn"
                                       size="25px" icon="delete" shape="circle" style={{marginLeft: 7}}/>
                          </Tooltip>
                       </Popconfirm>
                          <Tooltip title="change password">
                               <Button type={text[4]} name="btn" size="25px" icon="key" onClick={() => {
                                   this.showDrawer()
                               }} shape="circle" style={{marginLeft: 7}}/>
                          </Tooltip>
                    </span>
                ),
            }
        ];
        return (
            <Fragment>
                <PageHeader title="User" subTitle="user's details"
                            extra={[
                                <Button type="primary" onClick={this.openDrawer}><Icon type="plus"/>Add User</Button>
                            ]}
                />
                {/*Table Users*/}
                <Table

                    columns={columns}
                    dataSource={this.state.data}
                    onRow={(record) => ({
                        onClick: () => {
                            this.setState({
                                rowKey: record.key
                            })
                        }
                    })}
                    pagination={{
                        total: this.state.paginateTotal,
                        pageSize: 15
                    }}
                    loading={this.state.loading}
                />

                {/*Password Drawer*/}
                <Drawer
                    title="Password Change"
                    width={720}
                    onClose={this.onClose}
                    visible={this.state.visible}
                    loading={this.state.loading}
                >
                    <Form layout="vertical" hideRequiredMark onSubmit={this.changePassword}>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Old password">
                                    {getFieldDecorator('old_password', {
                                        rules: [{required: true, message: 'Please enter old password'}],
                                    })(<Input type="password" placeholder="Please enter old password"/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="New password">
                                    {getFieldDecorator('new_password', {
                                        rules: [{required: true, message: 'Please enter new password'}],
                                    })(<Input type="password" placeholder="Please enter new password"/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label="Confirm password">
                                    {getFieldDecorator('cconfirm_password', {
                                        rules: [{required: true, message: 'Please select confirm password'}],
                                    })(
                                        <Input type="password" placeholder="Please enter confirm password"/>
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>


                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                textAlign: 'right',
                            }}
                        >
                            <Button onClick={this.onClose} style={{marginRight: 8}}>
                                Cancel
                            </Button>
                            <Button htmlType="submit" type="primary" disabled={this.state.disabled}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Drawer>
                {/* Drawer end*/}

                {/*   Add User Drawer*/}
                <Drawer
                    title={this.state.drawerType === "post" ? "Create a new user" : "Edit user"}
                    width={720}
                    onClose={this.onclose}
                    visible={this.state.viewDrawer}
                >
                    <Form layout="vertical" hideRequiredMark
                          onSubmit={this.state.drawerType === "post" ? this.addUser : this.editUserData}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Name">
                                    {getFieldDecorator('name', {
                                        rules: [{required: true, message: 'Please enter user name'}],
                                    })(<Input placeholder="Please enter user name"/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Email">
                                    {getFieldDecorator('email', {
                                        rules: [{required: true, message: 'Please enter email'}],
                                    })(<Input placeholder="Please enter email"/>)}
                                </Form.Item>
                            </Col>
                        </Row>

                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="mobile">
                                    {getFieldDecorator('mobile', {
                                        rules: [{required: true, message: 'Please enter mobile'}],
                                    })(<Input placeholder="Please enter mobile"/>)}
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item label="Role">
                                    {getFieldDecorator('role', {
                                        rules: [{required: true, message: 'Please select a role'}],
                                    })(
                                        <Select placeholder="Please select a role">
                                            <Option value="admin">Admin</Option>
                                            <Option value="staff">Staff</Option>
                                        </Select>,
                                    )}
                                </Form.Item>
                            </Col>
                        </Row>

                        {this.state.drawerType === "post" ?
                            <div>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item label="Password">
                                            {getFieldDecorator('password', {
                                                rules: [{required: true, message: 'Please enter password'}],
                                            })(<Input type="password" placeholder="Please enter password"/>)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <Form.Item label="Confirm password">
                                            {getFieldDecorator('confirm_password', {
                                                rules: [{required: true, message: 'Please enter confirm password'}],
                                            })(<Input type="password" placeholder="Please enter confirm password"/>)}
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </div> : null}
                        <div
                            style={{
                                position: 'absolute',
                                left: 0,
                                bottom: 0,
                                width: '100%',
                                borderTop: '1px solid #e9e9e9',
                                padding: '10px 16px',
                                background: '#fff',
                                textAlign: 'right',
                            }}>
                            <Button onClick={this.onclose} style={{marginRight: 8}}>
                                Cancel
                            </Button>
                            <Button htmlType="submit" type="primary" disabled={this.state.disabled}>
                                Submit
                            </Button>
                        </div>
                    </Form>
                </Drawer>
                {/* Add User Drawer End  */}

                {/*View User data open Drawer */}
                <Drawer
                    width={720}
                    onClose={this.closeDrawer}
                    visible={this.state.viewUserDrawer}
                >
                    <Descriptions title="User Info" column={1} bordered>
                        <Descriptions.Item label="UserID">{this.state.getUserData.id}</Descriptions.Item>
                        <Descriptions.Item label="Name">{this.state.getUserData.name}</Descriptions.Item>
                        <Descriptions.Item label="Email">{this.state.getUserData.email}</Descriptions.Item>
                        <Descriptions.Item label="Mobile Number">{this.state.getUserData.mobile}</Descriptions.Item>
                        <Descriptions.Item label="Role">{this.state.getUserData.role}</Descriptions.Item>
                    </Descriptions>
                </Drawer>
                {/*View User data open Drawer End*/}
            </Fragment>

        );
    }
}

const User = Form.create()(newForm);
export default User