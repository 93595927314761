import React, {Fragment} from 'react';
import logo from "../assets/images/logo.png";
import Cookies from "universal-cookie";
import {
    Avatar,
    Dropdown,
    Icon,
    Layout,
    Menu,
    message,
    Button,
    Modal,
    Card,
    Input,
    Typography,
    Switch,
    Form
} from "antd";
import "../Layout/container.less";
import {Link, Redirect, Route,} from "react-router-dom";
import {ProtectedRoute} from "../ProtectedRoute";
import Dashboard from "../Pages/Dashboard";
import Order from "../Pages/Order";
import User from "../Pages/User";
import Customer from "../Pages/Customer";
import Notice from "../Pages/Notice";
import api from "../Api/api";

const {Header, Content, Sider} = Layout;


const cookies = new Cookies();
const {TextArea} = Input;
const {Title} = Typography;
const logout = () => {
    cookies.remove('isLoggedIn');
    cookies.remove('admin_data');
    cookies.remove('access_token');
    message.info('Successfully Logged Out');
    window.location.reload();
};

class container extends React.Component {
    constructor() {
        super();
        this.state = {
            noticeData: [],
            collapsed: false,
            status: null,
        };
        this.onChange = this.onChange.bind(this);
        this.postNotice = this.postNotice.bind(this);
    }

    componentDidMount() {
        api.get('auth/notice')
            .then((response) => {
                console.log(response)
                if (response.data.data.length > 0) {
                    response.data.data.map((data) => {
                        var data = {
                            description: data.description,
                            status: data.status
                        };
                        this.setState({
                            noticeData: data
                        });
                        console.log(data);
                    });
                    this.setState({
                        status: JSON.parse(response.data.data[0].status)
                    })
                    console.log(JSON.parse(response.data.data[0].status));
                    this.props.form.setFieldsValue({
                        description: response.data.data[0].description,
                    })
                }

            })
    }


    toggle = () => {
        this.setState({
            collapsed: !this.state.collapsed,
        });

    };

    showModal = (e) => {
        e.preventDefault();
        // this.props.form.validateFields(['description','status'] ,(err, values)=>{
        //     console.log(values);
        //     console.log(this.state.noticeData);
        //
        // });
        this.setState({
            visible: true,

        });

    };

    //Notice post api
    postNotice = (e) => {
        e.preventDefault();
        message.loading("Please wait...").then(() => {
            this.props.form.validateFields(['description', 'status'], (err, values) => {
                console.log(values);
                if (!err) {
                    if (values.status) {
                        values.status = "true"
                    } else {
                        values.status = "false"
                    }
                    api.post('auth/notice', values)
                        .then((response) => {
                            console.log(response)
                        })
                }
            })
            this.setState({
                visible:false
            })
        })
    };

    onChange = (checked) => {
        console.log(checked);
        if (checked) {
            this.setState({
                status: "true"
            })
        } else {
            this.setState({
                status: "false"
            })
        }
    };

    //Model Cancel
    handleCancel = () => {
        this.setState({visible: false});
    };


    render() {
        console.log(this.state.status);
        const {visible, loading} = this.state;
        const {getFieldDecorator} = this.props.form;
        const menu = (
            <Menu>
                <Menu.Item>
                    <Button type="link" onClick={logout}> <Icon npm="logout"/>Log out</Button>
                </Menu.Item>
            </Menu>
        );
        return (
            <Fragment>
                <div className="container">
                    <Layout>
                        <Sider trigger={null} collapsible collapsed={this.state.collapsed}
                               style={{backgroundColor: '#fff'}}>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center',
                                padding: '10px 0px',
                                marginBottom: 10
                            }}>
                                <img src={logo} width={108} height={42}/>
                            </div>
                            <Menu theme="light" mode="inline" defaultSelectedKeys={['1']}>
                                <Menu.Item key="1">
                                    <Link to="/dashboard">
                                        <Icon type="dashboard"/>
                                        <span>Dashboard</span></Link>
                                </Menu.Item>
                                <Menu.Item key="2">
                                    <Link to="/order">
                                        <Icon type="unordered-list"/>
                                        <span>Order</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="3">
                                    <Link to="/user">
                                        <Icon type="user"/>
                                        <span>Users</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="4">
                                    <Link to="/customer">
                                        <Icon type="team"/>
                                        <span>Customer</span>
                                    </Link>
                                </Menu.Item>
                                <Menu.Item key="5">
                                    <Link to="/notice" onClick={this.showModal}>
                                        <Icon type="file"/>
                                        <span>Notice</span>
                                    </Link>
                                </Menu.Item>
                            </Menu>
                        </Sider>
                        <Layout>
                            <Header style={{background: '#fff', padding: 0}}>
                                <Icon
                                    className="trigger"
                                    type={this.state.collapsed ? 'menu-unfold' : 'menu-fold'}
                                    onClick={this.toggle}
                                />
                                <Dropdown overlay={menu}>
                                    <Avatar icon="user"/>
                                </Dropdown>
                            </Header>
                            <Content className="content">
                                <ProtectedRoute path="/dashboard" component={Dashboard}/>
                                <ProtectedRoute path="/order" component={Order}/>
                                <ProtectedRoute path="/user" component={User}/>
                                <ProtectedRoute path="/customer" component={Customer}/>
                                <ProtectedRoute path="/notice" component={Notice}/>
                                {/*Model*/}
                                <Modal
                                    visible={visible}
                                    title="Notice"
                                    footer={null}
                                    onCancel={this.handleCancel}
                                >
                                    <Form onSubmit={this.postNotice}>
                                        <Form.Item label="Description">
                                            {getFieldDecorator('description', {
                                                rules: [{required: true, message: 'Please input your description!'}],
                                            })(
                                                <TextArea rows={4} width="75px"
                                                          placeholder="Please enter your description"/>
                                            )}
                                        </Form.Item>
                                        <Form.Item lable="Status">
                                            {getFieldDecorator('status', {
                                                rules: [{required: true, message: ''}],
                                            })(
                                                <Switch
                                                    checkedChildren={<Icon type="check"/>}
                                                    unCheckedChildren={<Icon type="close"/>}
                                                    onChange={this.onChange}
                                                    defaultChecked={this.state.status}
                                                />
                                            )}
                                        </Form.Item>
                                        <Form.Item>
                                            <div style={{float: "right"}}>
                                                <Button key="back" onClick={this.handleCancel}>
                                                    Cancel
                                                </Button>
                                                <Button htmlType="submit" type="primary" style={{marginLeft: 5}}>
                                                    Submit
                                                </Button>
                                            </div>
                                        </Form.Item>
                                    </Form>
                                </Modal>
                                {/*  Model End */}
                            </Content>
                        </Layout>
                    </Layout>
                </div>
            </Fragment>

        );
    }

}

const Container = Form.create()(container);
export default Container