import React, {Fragment} from 'react';
import {
    Button,
    Descriptions,
    Divider,
    Drawer,
    PageHeader,
    Table,
    Tooltip,
    Popconfirm,
    Icon,
    message,
    Modal, Form, Input, Select
} from "antd";
import api from "../Api/api";

const view = <span>view</span>;
const remove = <span>delete</span>;
const download = <span>download</span>;
const changestatus = <span>changestatus</span>

const {Option} = Select;

function cancel(e) {
    console.log(e);
    message.error('Click on No');
};

class orderform extends React.Component {

    constructor() {
        super();
        this.state = {
            data: [],
            visible: false,
            getData: {},
            loading: true,
            visiblemodel: false,
            status: "",
            getorderstatus: {},
            paginateTotal: 1,
        };
        this.getOrder = this.getOrder.bind(this);
        this.deleteOrder = this.deleteOrder.bind(this);
        this.editStatus = this.editStatus.bind(this);
        this.onPaginate = this.onPaginate.bind(this);
    }


    //Drawer close method
    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    //get data database to table using get api for order table
    componentDidMount() {
        api.get('auth/orders').then((response) => {
            console.log(response);
            this.setState({
                paginateTotal: response.data.total
            });
            var orderarr = response.data.data.map((data) => {
                if (data.status === "EXPERT") {
                    return {
                        id: data.id,
                        key: data.id,
                        name: data.name,
                        email: data.email,
                        mobile: data.mobile,
                        payment_id: data.payment_id,
                        order_type: data.order_type,
                        status: data.status,
                        action: ['view', 'delete', 'changestatus', 'status']
                    };
                } else {
                    return {
                        id: data.id,
                        key: data.id,
                        name: data.name,
                        email: data.email,
                        mobile: data.mobile,
                        payment_id: data.payment_id,
                        order_type: data.order_type,
                        status: data.status,
                        action: ['view', 'delete']
                    };
                }
            });
            this.setState({
                data: orderarr,
                loading: false
            })
        })
    }

    //pagination
    onPaginate(page, pageSize) {
        this.setState({loading: !this.state.loading});
        api.get('auth/orders?page=' + page).then((response) => {
            console.log(response)
                this.setState({
                    paginateTotal: response.data.total
                });
                var orderarr = response.data.data.map((data) => {
                    if (data.status === "EXPERT") {
                        return {
                            id: data.id,
                            key: data.id,
                            name: data.name,
                            email: data.email,
                            mobile: data.mobile,
                            payment_id: data.payment_id,
                            order_type: data.order_type,
                            status: data.status,
                            action: ['view', 'delete', 'changestatus', 'status']
                        };
                    } else {
                        return {
                            id: data.id,
                            key: data.id,
                            name: data.name,
                            email: data.email,
                            mobile: data.mobile,
                            payment_id: data.payment_id,
                            order_type: data.order_type,
                            status: data.status,
                            action: ['view', 'delete']
                        };
                    }
                });
                this.setState({
                    data: orderarr,
                })
            })
            .then(() => this.setState({loading: !this.state.loading}))
            .catch(err => {
                message.error(err.message);
                if (err) {
                    this.setState({
                        data: []
                    })
                }
            })
    }

    //View in click open Drawer
    getOrder(id) {
        console.log(id);
        api.get(`auth/orders/${id}`)
            .then((response) => {
                console.log(response);
                this.setState({
                    visible: true,
                    getData: response.data.order,
                });


            })
    }

    //Delete Order data
    deleteOrder(id) {
        console.log(id);
        message.loading("Please wait...").then(() => {
            api.delete(`auth/orders/${id}`)
                .then((response) => {
                    const {data} = this.state;
                    const newdata = data.filter(item => item.id != response.data.order.id);
                    this.setState({
                        data: newdata,
                    });
                    message.success("SuccessFully Delete Row");
                })
        })
    }


    //Download File
    downloadFile(sUrl) {

        //iOS devices do not support downloading. We have to inform user about this.
        if (/(iP)/g.test(navigator.userAgent)) {
            //alert('Your device does not support files downloading. Please try again in desktop browser.');
            window.open(sUrl, '_blank');
            return false;
        }

        //If in Chrome or Safari - download via virtual link click
        var isChrome = navigator.userAgent.toLowerCase().indexOf('chrome') > -1;
        var isSafari = navigator.userAgent.toLowerCase().indexOf('safari') > -1;
        if (isChrome || isSafari) {
            //Creating new link node.
            var link = document.createElement('a');
            link.href = sUrl;
            link.setAttribute('target', '_blank');

            if (link.download !== undefined) {
                //Set HTML5 download attribute. This will prevent file from opening if supported.
                var fileName = sUrl.substring(sUrl.lastIndexOf('/') + 1, sUrl.length);
                link.download = fileName;
            }

            //Dispatching click event.
            if (document.createEvent) {
                var e = document.createEvent('MouseEvents');
                e.initEvent('click', true, true);
                link.dispatchEvent(e);
                return true;
            }
        }

        // Force file download (whether supported by server).
        if (sUrl.indexOf('?') === -1) {
            sUrl += '?download';
        }

        window.open(sUrl, '_blank');
        return true;
    }

    downloadMedia(id, name, fileurl) {
        console.log(id)
        console.log(name)

        this.downloadFile(fileurl)
    }

    //data get in status
    showModal = (id) => {
        message.loading("Please wait...").then(() => {
            api.get(`auth/orders/${id}`)
                .then((response) => {
                    console.log(response);
                    this.setState({
                        getorderstatus: response.data.order
                    })
                    this.props.form.setFieldsValue({
                        status: response.data.order.status
                    })
                    this.setState({
                        visiblemodel: true,
                    });
                });
        })
    };

    handleOk = e => {
        console.log(e);
        this.setState({
            visiblemodel: false,
        });
    };

    handleCancel = e => {
        console.log(e);
        this.setState({
            visiblemodel: false,
        });
    };


    getRowByKey(key, newData) {
        const {data} = this.state;
        return (newData || data).filter(item => item.id == key)[0]
    }


    //edit data for status
    editStatus = (e) => {
        e.preventDefault();
        this.props.form.validateFields(['status'], (err, values) => {
            console.log(values);
            console.log(this.state.getorderstatus);
            api.put(`auth/orders/updateStatus/${this.state.getorderstatus.id}`, values)
                .then((response) => {
                    console.log(response);
                    if (response.data.success) {
                        this.setState({
                            visiblemodel: false
                        })
                        const {data} = this.state;
                        var newData = data.map(item => ({...item}));
                        console.log(newData);
                        const target = this.getRowByKey(response.data.order.id, newData);
                        console.log(target);
                        if (target) {
                            target['status'] = response.data.order.status;
                        }
                        this.setState({
                            data: newData,
                            disabled: false
                        })
                    }
                    this.props.form.resetFields();
                })
            message.success("SuccessFully Update...")
        })

    };

    render() {
        const {getFieldDecorator} = this.props.form;
        //table content
        const columns = [
            {title: 'OrderID', dataIndex: 'id', key: 'id'},
            {title: 'Name', dataIndex: 'name', key: 'name'},
            {title: 'Email', dataIndex: 'email', key: 'email'},
            {title: 'Mobile No', dataIndex: 'mobile', key: 'mobile'},
            {title: 'PayID', dataIndex: 'payment_id', key: 'payment_id'},
            {title: 'Order type', dataIndex: 'order_type', key: 'order_type'},
            {title: 'Status', dataIndex: 'status', key: 'status'},
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'x',
                render: (text, record) => (
                    <span>
                        <Tooltip placement="top" title={view}>
                         <Button type={text[1]} onClick={() => {
                             this.getOrder(record.id, "view")
                         }} shape="circle" icon="eye" style={{marginLeft: 7}}/>
                          </Tooltip>

                           <Popconfirm
                               title="Are you sure delete this task?"
                               onConfirm={() => {
                                   this.deleteOrder(record.id, "delete")
                               }}
                               onCancel={cancel}
                               okText="Yes"
                               cancelText="No"

                           >
                          <Tooltip placement="top" title={remove}>
                         <Button type={text[2]}
                                 shape="circle" icon="delete" style={{marginLeft: 7}}/>
                          </Tooltip>
                           </Popconfirm>

                        {record.order_type === "EXPERT" ?
                            <Tooltip placement="top" title={"Change Status"}>
                                <Button type={text[3]} shape="circle" onClick={() => {
                                    this.showModal(record.id, "changestatus")
                                }} icon="book" style={{marginLeft: 7}}/>
                            </Tooltip>
                            : null}
                    </span>
                ),
            }
        ];
        return (

            <Fragment>
                <PageHeader title="Order" subTitle="display in order list "/>
                {/*Table*/}
                <Table
                    columns={columns}
                    loading={this.state.loading}
                    dataSource={this.state.data}
                    pagination={{
                        total: this.state.paginateTotal,
                        onChange: this.onPaginate,
                        pageSize:15
                    }}
                />

                <Drawer
                    width={640}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    {/*<p style={{...pStyle, marginBottom: 24}}>Display Order</p>*/}
                    {/*<p style={pStyle}>Order</p>*/}
                    <Descriptions title="User Order Info" column={1} bordered>
                        <Descriptions.Item label="Order ID">{this.state.getData.id}</Descriptions.Item>
                        <Descriptions.Item label="Name">{this.state.getData.name}</Descriptions.Item>
                        <Descriptions.Item label="Email">{this.state.getData.email}</Descriptions.Item>
                        <Descriptions.Item label="Mobile Number"> {this.state.getData.mobile}</Descriptions.Item>
                        <Descriptions.Item label="Payment ID">
                            {this.state.getData.payment_id}
                        </Descriptions.Item>
                        <Descriptions.Item label="order_type">
                            {this.state.getData.order_type}
                        </Descriptions.Item>
                        <Descriptions.Item label="order_type">
                            {this.state.getData.status}
                        </Descriptions.Item>
                    </Descriptions>
                    {/*Form Open*/}
                    {this.state.getData.order_type === "EXPERT" && this.state.getData.media.length > 0 ?
                        <div style={{marginTop: 25}}>
                            <h1 style={{fontSize: 25}}>Form</h1>
                            <PageHeader title={this.state.getData.media[0].file_name} extra={[
                                <Tooltip placement="top" title={download}>
                                    <Button onClick={() => {
                                        this.downloadMedia(this.state.getData.id, this.state.getData.media[0].file_name, this.state.getData.url)
                                    }} shape="circle" download><Icon type="download"/></Button>
                                </Tooltip>
                            ]}/>
                        </div> : null}

                    {/*Form close */}
                </Drawer>

                <Modal
                    title="Change Status"
                    visible={this.state.visiblemodel}
                    onOk={this.handleOk}
                    onCancel={this.handleCancel}
                    footer={null}
                >
                    <Form onSubmit={this.editStatus}>
                        <Form.Item label="Status" hasFeedback>
                            {getFieldDecorator('status', {
                                rules: [{required: true, message: 'Please select your description!'}],
                            })(
                                <Select placeholder="Please select a description">
                                    <Option value="Pending">Pending</Option>
                                    <Option value="InProgress">InProgress</Option>
                                    <Option value="Assigned">Assigned</Option>
                                    <Option value="Filed">Filed</Option>
                                </Select>,
                            )}
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                                Submit
                            </Button>

                        </Form.Item>
                    </Form>

                </Modal>


            </Fragment>
        );
    }
}

const Order = Form.create()(orderform);
export default Order