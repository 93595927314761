import React, {Fragment} from 'react';
import {Button, Card, Col, Icon, PageHeader, Row, Skeleton, Statistic, Table, Typography} from "antd";
import api from "../Api/api";


const {Title} = Typography;

class Dashboard extends React.Component {
    constructor() {
        super();
        this.state = {
            orderdata: [],
            customerdata: [],
            totalOrders: "",
            totalCustomers: "",
            totalPayment: "",
            paymentpercentage: "",
            orderpercentage: "",
            customerpercentage: "",
            loading: true
        }
    }

    async componentDidMount() {
        //Latest Order List
        api.get('auth/latestorders')
            .then((response) => {
                var orderarr = response.data.map((data) => {
                    return {
                        id: data.id,
                        key: data.id,
                        name: data.name,
                        order_type: data.order_type
                    };
                });
                this.setState({
                    orderdata: orderarr,
                })
            });

        //Latest Customer List
        await api.get('auth/latestcustomers')
            .then((response) => {
                console.log(response);
                var custarr = response.data.map((data) => {
                    return {
                        id: data.id,
                        key: data.id,
                        name: data.name,
                        mobile: data.mobile
                    };
                });
                this.setState({
                    customerdata: custarr,
                })
            });


        //Total Payment
        await api.get('auth/totalpayments')
            .then((response) => {
                console.log(response);
                var paymentpercentage = parseFloat(response.data.percentage);
                var totalPayments = response.data.totalPayment;
                var totalPayment = totalPayments.toLocaleString("en-IN", {style: "currency", currency: "INR"});
                this.setState({
                    totalPayment: totalPayment,
                    paymentpercentage: paymentpercentage,
                    loading: false
                })
            });


        //Total Order
        await api.get('auth/totalorders')
            .then((response) => {
                console.log(response);
                var orderpercentage = parseFloat(response.data.percentage);
                this.setState({
                    totalOrders: response.data.totalOrders,
                    orderpercentage: orderpercentage
                })
            });

        //Total Customer
        await api.get('auth/totalcustomers')
            .then((response) => {
                console.log(response);
                var customerpercentage = parseFloat(response.data.percentage);
                this.setState({
                    totalCustomers: response.data.totalCustomers,
                    customerpercentage: customerpercentage
                })
            });
    }


    render() {
        //Order Table
        const columns = [
            {title: 'OrderID', dataIndex: 'id', key: 'id'},
            {title: 'Name', dataIndex: 'name', key: 'name'},
            {title: 'Order Type', dataIndex: 'order_type', key: 'order_type'},
        ];


        //Customer Table
        const column = [
            {title: 'CustomerID', dataIndex: 'id', key: 'id'},
            {title: 'Name', dataIndex: 'name', key: 'name'},
            {title: 'Mobile', dataIndex: 'mobile', key: 'mobile'},
        ];


        return (

            <Fragment>
                <div style={{padding: '30px'}}>
                    <Row gutter={14}>
                        <Col span={8}>
                            <Skeleton loading={this.state.loading} active>
                                <Card title="Total Payment" bordered={true}>
                                    <Title level={4}>
                                        {this.state.totalPayment}</Title>
                                    {this.state.paymentpercentage >= 0 ?
                                        <Statistic
                                            value={this.state.paymentpercentage}
                                            precision={2}
                                            valueStyle={{color: '#3f8600'}}
                                            prefix={<Icon type="arrow-up"/>}
                                            suffix="%"
                                        /> : <Statistic
                                            value={this.state.paymentpercentage}
                                            precision={2}
                                            valueStyle={{color: '#cf1322'}}
                                            prefix={<Icon type="arrow-down"/>}
                                            suffix="%"
                                        />}
                                </Card>
                            </Skeleton>
                        </Col>
                        <Col span={8}>
                            <Skeleton loading={this.state.loading} active>
                                <Card title="Total Orders" bordered={true}>
                                    <Title level={4}>{this.state.totalOrders}</Title>
                                    {this.state.orderpercentage >= 0 ?
                                        <Statistic
                                            value={this.state.orderpercentage}
                                            precision={2}
                                            valueStyle={{color: '#3f8600'}}
                                            prefix={<Icon type="arrow-up"/>}
                                            suffix="%"
                                        /> : <Statistic
                                            value={this.state.orderpercentage}
                                            precision={2}
                                            valueStyle={{color: '#cf1322'}}
                                            prefix={<Icon type="arrow-down"/>}
                                            suffix="%"
                                        />}
                                </Card>
                            </Skeleton>
                        </Col>
                        <Col span={8}>
                            <Skeleton loading={this.state.loading} active>
                                <Card title="Total Customers" bordered={true}>
                                    <Title level={4}>{this.state.totalCustomers}</Title>
                                    {this.state.customerpercentage >= 0 ?
                                        <Statistic
                                            value={this.state.customerpercentage}
                                            precision={2}
                                            valueStyle={{color: '#3f8600'}}
                                            prefix={<Icon type="arrow-up"/>}
                                            suffix="%"
                                        /> : <Statistic
                                            value={this.state.customerpercentage}
                                            precision={2}
                                            valueStyle={{color: '#cf1322'}}
                                            prefix={<Icon type="arrow-down"/>}
                                            suffix="%"
                                        />}
                                </Card>
                            </Skeleton>
                        </Col>
                    </Row>
                </div>

                <div>
                    <Row gutter={16}>
                        {/*Order Table*/}
                        <Col span={12}>
                            <Skeleton loading={this.state.loading} active>
                                <Card bordered={false}>
                                    <PageHeader title="Order" subTitle="Latest Order" extra={[
                                        <a href="/Order" size="25px" style={{color: "#63AB50"}}>View All</a>
                                    ]}/>
                                    <Table
                                        columns={columns}
                                        dataSource={this.state.orderdata}
                                        pagination={false}
                                        loading={this.state.loading}

                                    />
                                </Card>
                            </Skeleton>
                        </Col>
                        {/*Order Table End*/}
                        {/*Customer Table*/}
                        <Col span={12}>
                            <Skeleton loading={this.state.loading} active>
                                <Card bordered={false}>
                                    <PageHeader title="Customer" subTitle="Latest Customer" extra={[
                                        <a href="/Customer" size="25px" style={{color: "#63AB50"}}>View All</a>
                                    ]}/>
                                    <Table
                                        columns={column}
                                        dataSource={this.state.customerdata}
                                        pagination={false}
                                        loading={this.state.loading}
                                    />
                                </Card>
                            </Skeleton>
                        </Col>
                        {/* Customer Table End  */}
                    </Row>
                </div>
            </Fragment>

        );
    }

}

export default Dashboard