import React, {Fragment} from 'react';
import {Button, Col, Descriptions, Divider, Drawer, PageHeader, message, Row, Table, Tooltip, Popconfirm} from "antd";
import Dashboard from "./Dashboard";
import api from "../Api/api";


const view = <span>view</span>;
const remove = <span>delete</span>;

//Delete method for popfiled
function confirm(e) {
    console.log(e);
    message.success('Click on Yes');
};

function cancel(e) {
    console.log(e);
    message.error('Click on No');
};


class Customer extends React.Component {

    //constructor
    constructor() {
        super();
        this.state = {
            data: [],
            visible: false,
            getData: null,
            loading: true,
            paginateTotal: 1
        };
        this.customerData = this.customerData.bind(this);
        this.deleteCustomer = this.deleteCustomer.bind(this);
        this.onPaginate = this.onPaginate.bind(this)
    }

    //when page renders
    componentDidMount() {
        api.get('auth/users/filter/role')
            .then((response) => {
                this.setState({
                    paginateTotal: response.data.total
                })
                console.log(response);
                var customerarr = response.data.data.map((data) => {
                    return {
                        id: data.id,
                        key: data.id,
                        name: data.name,
                        email: data.email,
                        mobile: data.mobile,
                        action: ['view', 'delete']
                    };

                });
                this.setState({
                    data: customerarr,
                    loading: false
                })
            })
    }

    //get customer data
    customerData(id) {
        console.log(id);
        api.get(`auth/users/${id}`)
            .then((response) => {
                console.log(response);
                this.setState({
                    visible: true,
                    getData: response.data.user
                });
            })
    }

    //on drawer close
    onClose = () => {
        this.setState({
            visible: false,
        });
    };

    //delete customer
    deleteCustomer(id) {
        console.log(id);
        message.loading("Please wait...")
            .then(() => {
                api.delete(`auth/users/${id}`)
                    .then((response) => {
                        const {data} = this.state;
                        var newData = data.filter(item => item.id != response.data.user.id);
                        this.setState({
                            data: newData
                        });
                        message.success("SuccessFully Delete Row ");
                    })
            })
    }

    //pagination
    onPaginate(page) {
        console.log(page)
        this.setState({
            loading: true,
        });
        api.get('auth/users/filter/role?page=' + page).then((response) => {
            console.log(response)
            this.setState({
                paginateTotal: response.data.total,
            });
            var dataArr = []
            response.data.data.map((data) => {
                var type = {
                    id: data.id,
                    key: data.id,
                    name: data.name,
                    email: data.email,
                    mobile: data.mobile,
                    action: ['view', 'delete']
                };
                dataArr.push(type)
            });
            this.setState({
                data: dataArr,
                loading: false
            });

        }).then(() => {
            this.setState({
                loading: false
            })
        })
    }

    render() {
        const columns = [
            {title: 'Customer ID', dataIndex: 'id', key: 'id'},
            {title: 'Name', dataIndex: 'name', key: 'name'},
            {title: 'Email', dataIndex: 'email', key: 'email'},
            {title: 'Mobile no', dataIndex: 'mobile', key: 'mobile'},

            {
                title: 'Action',
                dataIndex: 'action',
                key: 'x',
                render: (text, record) =>
                    <span>
                     <Tooltip title="View">
                         <Button type={text[1]} shape="circle" icon="eye" onClick={() => {
                             this.customerData(record.id, "view")
                         }}/>
                     </Tooltip>

                    <Popconfirm
                        title="Are you sure delete this task?"
                        onConfirm={() => {
                            this.deleteCustomer(record.id, "delete")
                        }}
                        onCancel={cancel}
                        okText="Yes"
                        cancelText="No"
                    >
                    <Tooltip title="remove">
                         <Button type={text[2]} shape="circle" icon="delete"/>
                     </Tooltip>
                    </Popconfirm>
                </span>
            },
        ];

        return (
            <Fragment>
                <PageHeader title="Customer" subTitle="Display customer list"/>
                <Table
                    columns={columns}
                    dataSource={this.state.data}
                    pagination={{
                        total: this.state.paginateTotal,
                        pageSize: 15,
                        onChange:this.onPaginate
                    }}
                    loading={this.state.loading}
                />


                {this.state.getData !== null ? <Drawer
                    width={640}
                    placement="right"
                    closable={false}
                    onClose={this.onClose}
                    visible={this.state.visible}
                >
                    <Descriptions title="Customer Info" column={1} bordered>
                        <Descriptions.Item label="CustomerID">{this.state.getData.id}</Descriptions.Item>
                        <Descriptions.Item label="Name">{this.state.getData.name}</Descriptions.Item>
                        <Descriptions.Item label="Email">{this.state.getData.email}</Descriptions.Item>
                        <Descriptions.Item label="Mobile">{this.state.getData.mobile}</Descriptions.Item>

                    </Descriptions>
                </Drawer> : null}
            </Fragment>
        );
    }
}

export default Customer