import React, {Fragment} from 'react';
import {Button, Card, Icon, Input, message, Form} from "antd";
import logo from "../assets/images/logo.png";
import "../Pages/login.less";
import Cookies from "universal-cookie";
import Auth from "../Api/auth";
import {Redirect} from "react-router";

const cookies = new Cookies();

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect: false,
            isLoggedIn: false,
            disabled: false,
            loading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
    }


    handleSubmit = (e, props = this.props) => {
        e.preventDefault();
        this.setState({disabled: true, loading: true});
        this.props.form.validateFields((err, values, that = this) => {
            if (!err) {
                Auth.login(values.email, values.password, function (response) {
                    console.log(response);
                    if (response.data.success === true) {
                        let admin_data = {
                            'id': response.data.user.id,
                            'name': response.data.user.name,
                            'email': response.data.user.email,
                            "mobile": response.data.user.mobile,
                            "role": response.data.user.role

                        };
                        cookies.set('access_token', response.data.access_token, {
                            expirationTime: response.data.expires_at
                        });
                        cookies.set('admin_data', JSON.stringify(admin_data));
                        cookies.set("isLoggedIn", 'true');
                        message.info("Success fully Login");
                        props.history.push('/dashboard');
                        window.location.reload()
                    } else {
                        that.setState({
                            disabled: false,
                            loading: false
                        });
                        message.error("Inccorect");
                    }

                })
            }
        });
    };

    render() {
        const {getFieldDecorator} = this.props.form;
        console.log(cookies.getAll())
        if (cookies.get('isLoggedIn')=== 'true') {
            console.log(cookies.getAll())
            return <Redirect to="/"/>
        }

        return (
            <Fragment>
                <div className="login-container">
                    <div className="card">
                        <Card
                            hoverable
                            style={{
                                width: 350, boxShadow: ' 0 10px 20px rgba(0,0,0,0.10),0 6px 6px rgba(0,0,0,0.10)'
                            }}
                            cover={<img alt="example" src={logo}
                                        style={{width: '162px', height: '123px', padding: 5}}/>}>
                            <Form onSubmit={this.handleSubmit} className="form">
                                <p style={{fontSize: 25, marginBottom: 10}}> Login</p>
                                <Form.Item>
                                    {getFieldDecorator('email', {
                                        rules: [{required: true, message: 'Please input your email!'}],
                                    })(
                                        <Input type="email"
                                               prefix={<Icon type="user" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                               placeholder="Email"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    {getFieldDecorator('password', {
                                        rules: [{required: true, message: 'Please input your Password!'}],
                                    })(
                                        <Input
                                            prefix={<Icon type="lock" style={{color: 'rgba(0,0,0,.25)'}}/>}
                                            type="password"
                                            placeholder="Password"
                                        />,
                                    )}
                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button"
                                            disabled={this.state.disabled} loading={this.state.loading}>
                                        Log in
                                    </Button>
                                </Form.Item>
                            </Form>
                            <div style={{display: "flex", justifyContent: "center"}}>
                                <a className="login-form-forgot" href="">
                                    Forgot password?
                                </a>
                            </div>
                        </Card>
                    </div>
                </div>
            </Fragment>
        );
    }
}

const Login = Form.create({name: 'LoginForm'})(LoginForm);

export default Login