import axios from 'axios';
import Cookies from "universal-cookie";

const cookies = new Cookies();

const api = axios.create({
    baseURL: 'http://api.meracacs.com/api/',
    timeout: 3000,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + cookies.get('access_token')
    }
});


export default api