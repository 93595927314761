import React, {Fragment} from 'react';

import './App.less';

import {createBrowserHistory} from 'history';
import {Route, Switch} from 'react-router';
import {Link, BrowserRouter as Router} from "react-router-dom";
import Container from "../src/Layout/Container";
import Login from './Pages/Login';
import {ProtectedRoute} from "./ProtectedRoute";

const history = createBrowserHistory();

const LoginContainer = () => (
    <div>
        <Route path="/login" component={Login}/>
    </div>
);

class App extends React.Component {

    render() {
        return (
            <Fragment>
                <Router history={history}>
                    <div>
                        <Switch>
                            <Route exact path="/login" component={LoginContainer}/>
                            <ProtectedRoute path="/" component={Container}/>
                        </Switch>
                    </div>
                </Router>
            </Fragment>
        );
    }
}


export default App;